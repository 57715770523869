import React from 'react';
import '../styles/App.scss';
import {GoogleMap, withScriptjs, withGoogleMap, Marker} from 'react-google-maps';

function Map() {
  return (            
      <GoogleMap 
        defaultZoom={16}
        defaultCenter={ {lat: 49.944842, lng: -97.154786}  }
      >
        <Marker
        position={ {lat: 49.944842, lng: -97.154786} } />
      </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function TheMap() {
  return (
    <div className="contact-map-wrapper box-shadow" >
      <WrappedMap 
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBMe8D1dlFLkVVUTGtPHKO9V-o-xxQh79w`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}/>
    </div>
  )
};

