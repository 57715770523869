import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout";
import HomeVisual from "../components/Home/HomeVisual";
import Services from "../components/Services/Services";
import Implants from "../components/Services/ImplantHome"
import About from "../components/About/About";
import EmailForm from "../components/Email/EmailForm";
import Contact from "../components/Contact/Contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeVisual />
    <Services />
    <Implants />
    <About />
    <Contact />
    <EmailForm />
  </Layout>
)

export default IndexPage
