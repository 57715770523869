import React from "react"
import "../styles/App.scss"
import { Link } from "gatsby"

class Implants extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="implants-wrapper" id="implants">
        <div className="info-and-button">
          <div>
            <h1>Dental Implants</h1>
            <div>
              In addition to offering a full range of dental services of a
              family practice, Dr. Fernandes has a special interest in Implant
              Dentistry and has been providing complete implant treatment to
              patients from all over Manitoba and Ontario for over 30 years.
              Through his many years of experience with dental implant therapy,
              Dr. Fernandes has developed many groundbreaking techniques and
              tools used in many parts of the world, focused on minimizing post
              operative discomfort and time spent in the dental chair.
            </div>
          </div>
          <Link className="inquire-button-wrapper" to="/implants">
            <button className="inquire-button" id="implant-inquire-button">
              Learn More
            </button>
          </Link>
        </div>
        <div className="implant-benefits box-shadow">
          <h2>Why should you chose Dental Implants?</h2>
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>The best way to replace missing teeth</span>
          </div>
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>
              Prevents loss of facial bone associted with missing teeth
            </span>
          </div>
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>Longest lasting and most natural looking results</span>
          </div>
          {/* <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span></span>
          </div> */}
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>Maintains the structural integrity of adjacent teeth</span>
          </div>
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>Decreases the need for Root Canal therapy</span>
          </div>
          <div className="implant-point">
            <i className="material-icons">done_outline</i>
            <span>
              Perfect for the stabilization of unstable and non retentive
              dentures
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Implants
