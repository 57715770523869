import React from "react"
import "../styles/App.scss"
import Image from "../image"

function About() {
  return (
    <div className="about-wrapper" id="about">
      <div className="hr-wrapper">
        <hr />
      </div>
      <h1>About Us</h1>
      <div className="about-dentist">
        <div className="dentist-image-wrapper">
          <Image />
        </div>
        <div className="info-wrapper">
          <h3>Dr. Americo Fernandes</h3>
          <p id="dentist-about-paragraph">
            Dr. Americo Fernandes has practiced family dentistry since he
            graduated from the University of Manitoba Faculty of Dentistry in
            1984. With over 30 years of experience, he has built a strong
            practice mainly through the referrals of both happy patients and
            other dentists. In addition to general dentistry, Dr. Fernandes has
            focused on dental implant dentistry through much of his career. He
            has trained dentists in Canada, USA, Taiwan, and China how to
            perform all aspects of dental implant dentistry.
          </p>
          <h3>Our Team</h3>
          <p>
            Our staff have been working together for many years, allowing us to
            develop a caring and efficient team. We believe that your treatment
            is not a race and we will never rush you through the door.
            Every patient we see becomes part of our family and we are
            completely devoted to your comfort and care.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About

export const pageQuery = graphql`
  query DentistImageQuery {
    dentistImage: imageSharp(id: { regex: "/dentist/" }) {
      sizes(maxWidth: 1240) {
        ...GatsbyImageSharpSizes
      }
    }
  }
`
