import React from "react"
import "../styles/App.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

function HomeVisual(props) {
  return (
    <div className="home-page-visual">
      <div className="home-page-header-wrapper">
        {/* <h1 className="home-page-header">Dr. Americo Fernandes</h1> */}
        <h2>Quality Family Dentistry For Over 30 Years</h2>
        <div>
          Dr. Fernandes operates his practice on a philosophy of minimally
          invasive dentistry. The benefits are significantly reduced costs to
          patients and better oral health through minimizing the loss of tooth
          structure.
        </div>
      </div>
      <AnchorLink offset={100} href="#services">
        <button className="button primary" id="find-out-more">
          Learn More
        </button>
      </AnchorLink>
    </div>
  )
}

export default HomeVisual
