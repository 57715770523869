import React from 'react';
import '../styles/App.scss';
import ContactDetails from './ContactDetails';
import TheMap from './ContactMap';

function Contact() {
  return (
        <div className="contact-wrapper" id="contact">
            <div className="contact-map-details-wrapper " >
                <ContactDetails />
                <TheMap />
            </div>
        </div>
  );
}

export default Contact;

