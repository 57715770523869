import React from "react"
import "../styles/App.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

function ContactDetails() {
  return (
    <div className="contact-details-wrapper">
      <h1>Contact Us</h1>
      <h4>Address</h4>
      <p>
        2055 McPhillips Street
        <br />
        Winnipeg, Manitoba
        <br />
        R2V 3C6
        <br />
        <i className="material-icons">map</i>
        <a href="https://www.google.com/maps/dir//Fernandes+A+Dr,+2055+McPhillips+St,+Winnipeg,+MB+R2V+3C6/@49.9448892,-97.1626708,15z/data=!4m16!1m6!3m5!1s0x0:0xe1115fc68102a640!2sFernandes+A+Dr!8m2!3d49.9448063!4d-97.1547744!4m8!1m0!1m5!1m1!1s0x52ea71f380c863a1:0xe1115fc68102a640!2m2!1d-97.1547744!2d49.9448063!3e2">
          Get Directions
        </a>
      </p>
      <h4>Email</h4>
      <p id="email-info">
        <AnchorLink style={{marginRight: '5px'}} offset={100} href={"#email"}>
          Email here
        </AnchorLink>
        or directly at
        <a style={{marginLeft: '5px'}} href="to:drfernandesdentalclinic@gmail.com">
          drfernandesdentalclinic@gmail.com
        </a>
      </p>
      <h4>Phone</h4>
      <p>(204) 339-2675</p>
      <h4>Fax</h4>
      <p>(204) 339-2675</p>
    </div>
  )
}

export default ContactDetails
