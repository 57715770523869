import React from "react"
import "../styles/App.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

function Services() {
  return (
    <div className="services-wrapper" id="services">
      <div className="hr-wrapper">
        <hr />
      </div>
      <h1>Our Services</h1>
      <div className="list-wrapper">
        <ul>
          <li>Family Dentistry</li>
          <li>Preventative</li>
          <li>Fillings</li>
          <li>
            <AnchorLink offset={100} href="#implants">
              <span className="service-list-link">Dental Implants</span>
            </AnchorLink>
          </li>
          <li>Bridges</li>
          <li>Crowns</li>
          <li>Dentures</li>
          <li>Extractions</li>
          <li>Full Mouth Reconstruction</li>
          <li>Inlays and Onlays</li>
          <li>Mouth Guards/Night Guards</li>
          <li>Periodontal Care</li>
          <li>Root Canal Therapy</li>
        </ul>
      </div>
      <div className="service-inquiry-wrapper">
        <h3>Have any more questions?</h3>
        <AnchorLink
          offset={100}
          className="inquire-button-wrapper"
          href="#email"
        >
          <button className="inquire-button">Inquire Here</button>
        </AnchorLink>
      </div>
    </div>
  )
}

export default Services
